import { TRACK } from '@updater-core/lib/tracker';
import tracker from '@updater-core/interactors/shell/tracker';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { AllowedPaths } from './allowed-paths.ts';

export async function redirectIntoConsumerApp() {
  if (typeof window === 'undefined') {
    return false;
  }

  const targetLocation = window.location.pathname;
  const allowedPathIndex = AllowedPaths.findIndex((path) =>
    targetLocation.includes(path)
  );
  if (allowedPathIndex !== -1) {
    await tracker.actions.track({
      domain: 'mover',
      object: 'consumer_app_redirect',
      verb: TRACK,
      details: {
        productionized: true,
        tviRoute: targetLocation.includes('/tvi'),
        allowedBecausePathIncludes: AllowedPaths[allowedPathIndex],
        redirected: false,
      },
    });
    return false;
  }

  await tracker.actions.track({
    domain: 'mover',
    object: 'consumer_app_redirect',
    verb: TRACK,
    details: {
      productionized: true,
      tviRoute: false,
      redirected: true,
    },
  });

  console.log('REDIRECTED INTO CONSUMER APP');
  window.location.assign(ENVIRONMENT.endpoints.consumerAppUrl);

  return true;
}
