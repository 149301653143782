import { createGateway as createLocalStorageGateway } from '@updater-core/gateways/local-storage';
import { createInteractor } from './interactor';
import { createGateway as createRemoteGateway } from './remote.gateway';
import { createGateway as createStorageGateway } from './storage.gateway';

export function buildSession() {
  const remoteGateway = createRemoteGateway();
  const localStorageGateway = createLocalStorageGateway();
  const sessionStorageGateway = createStorageGateway({
    storageGateway: localStorageGateway,
  });
  const interactor = createInteractor({
    remoteGateway,
    storageGateway: sessionStorageGateway,
    window: typeof window === 'undefined' ? {} : window,
  });

  return interactor;
}
